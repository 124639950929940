@tailwind base;
@tailwind components;
@tailwind utilities;


.grid-rows-layout {
  grid-template-rows: 25% 20% 55%;
}



/* index.css */

@keyframes backgroundTransition {
  from { background-color: initial; }
  to { background-color: final-color; }
}


@keyframes easy-animation {
  0% { background-color: green; }
  100% { background-color: lightgreen; }
}

@keyframes moderate-animation {
  0% { background-color: yellow; }
  100% { background-color: lightyellow; }
}

@keyframes hard-animation {
  0% { background-color: red; }
  100% { background-color: lightcoral; }
}

.bg-easy-animation {
  animation: easy-animation 1s ease-in-out;
}

.bg-moderate-animation {
  animation: moderate-animation 1s ease-in-out;
}

.bg-hard-animation {
  animation: hard-animation 1s ease-in-out;
}

